import {HiChevronDown} from 'react-icons/hi'
import { useState,useEffect } from 'react'

const StoreData = ({store}) => {
  const [show, setShow] = useState(false)
  const [overflow, setOverflow] = useState(false)

  useEffect(()=> {
    setShow(false)
    const e = document.getElementById(`${store.name}-brands`);
    if (e.clientHeight < e.scrollHeight) {
        setOverflow(true)
    } else{
      setOverflow(false)
    }
  },[store])
  return(
    <tr className={store.name}>
      <td className="text-[0.65rem] min-[700px]:text-sm min-[790px]:text-base w-full py-1 sm:py-2 pl-1 pr-1 sm:pl-2 border-b border-gray-200" >
        <div className=" flex pb-1 w-full">
          <a className="text-dark-orange underline hover:no-underline w-1/3  max-[535px]:w-5/12" href={store.link} rel="noreferrer" target="_blank" >{store.name}</a>
          <div className=' w-1/2 flex max-[535px]:hidden overflow-hidden h-4 min-[790px]:h-6' id={`${store.name}-brands`}> 
            {store.brands}
          </div>
          <button className='w-1/6 max-[535px]:w-7/12 text-right flex align-right text-dark-orange hover:text-hover-orange' onClick={() => setShow(!show)}>
            <div className='w-1/3 max-[535px]:w-2/3'></div>
            <span className=''>Lisätiedot</span>
            <span className='align-baseline flex items-right justify-center pt-0.5 sm:pt-1'><HiChevronDown/></span>
          </button>
        </div>
        <div className={`flex p-1 rounded-md shadow-lg bg-white text-[0.6rem] min-[790px]:text-sm min-[700px]:text-xs ${show ? '' :'hidden'}`}>
          <span className='w-2/3'>
            {store.brands}
          </span>
          <div className='w-1/3 flex'>
            <div className={`w-1/3 ${store.orderLink !== '' ?'' : 'hidden'}`}/>
            <div className={` w-2/3 ${store.orderLink !== '' ?'' : 'hidden'} flex align-middle justify-center`}>
              <a className={`text-dark-orange hover:text-hover-orange align-middle`} href={store.orderLink} target="_blank" rel="noreferrer">Tee tilaus</a>        
            </div>
            <div className={`${store.orderLink === '' ?'' :'hidden'} `} >Tilaus {store.orderIns}</div>
          </div>
        </div> 
      </td>
    </tr>
  )
}

export default StoreData