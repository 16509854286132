// Import required libraries and components
import { useState, useEffect } from "react";
import getAll from "./services/stores";
import StoreData from "./components/StoreData";
import { onSuccess } from "./services/functions";
import Pagination from "@mui/material/Pagination";
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a custom theme for Material UI components
const theme = createTheme({
  palette:{
    warning:{
      main: '#ff6647'
    }
  }
});

// Define the main application component
const App = () => {
  // Define constants and states used within the component
  const itemsPerPage = 10; // Number of items per page for pagination
  const [checked, setChecked] = useState(false); // State for checked switch
  const [webStores, setWebStores] = useState([]); // State to hold all store data
  const [filtered, setFiltered]  = useState([]); // State to hold filtered store data
  const [curInput, setCurInput] = useState(''); // State to hold current input value for search
  const [paginationStart, setPaginationStart] = useState(0); // State to hold start of pagination
  const [paginationEnd, setPaginationEnd] = useState(itemsPerPage); // State to hold end of pagination
  const [pageCount, setPageCount] = useState(1); // State to hold total number of pages
  const [loadDone, setLoadDone] = useState(false); // State to indicate if data loading is complete
  const [page, setPage] = useState(1); // State to hold current page number

  // useEffect to fetch all store data when component mounts
  useEffect(() => {
    getAll().then(stores => {    
      const res = onSuccess(stores);
      setWebStores(res);
      setFiltered(res);
      setPageCount(Math.ceil(res.length / itemsPerPage));
      setLoadDone(true);
    });
  }, []);

  // useEffect to filter stores by addons and brand search
  useEffect(() => {
    const filterStoresByAddonsAndBrandSearch = () => {
      const list = webStores.filter((store) => {
        const filterByAddons = checked ? store.gear === 'kyllä' : true;
        const filterByKeyword = store.brands.toLowerCase().trim().includes(curInput.toLowerCase().trim());
        return filterByAddons && filterByKeyword;
      });
      if (list.length <= paginationStart) {
        setPaginationStart(0);
        setPaginationEnd(itemsPerPage);
        setFiltered(list.slice(0, itemsPerPage));
        setPageCount(Math.ceil(list.length / itemsPerPage));
        setPage(1);
      } else {
        setFiltered(list.slice(paginationStart, paginationEnd));
        setPageCount(Math.ceil(list.length / itemsPerPage));
      }
    };
    filterStoresByAddonsAndBrandSearch();
  }, [webStores, paginationStart, paginationEnd, checked, curInput, page]);

  // Function to handle pagination change
  const goToPage = (event, value) => {
    setPage(value);
    setPaginationStart((value - 1) * itemsPerPage);
    setPaginationEnd(value * itemsPerPage);
  };

  // Render component
  return (
        <div className='bg-light-orange h-screen font-light text-xs p-1'>
          <div className="bg-white min-h-[50%] sm:min-h-[58%] min-[700px]:min-h-[63%] min-[790px]:min-h-[68%] p-1 rounded shadow">
            <div className='w-full px-2 py-3 min-[290px]:flex min-[700px]:text-sm' id="navbar" >
              <div className="min-[270px]:w-2/3 max-[290px]:p-1">
                <input type="text" className="rounded-lg p-0.5 bg-gray-200 px-2" placeholder="Hae merkkiä" onChange={e => setCurInput(e.target.value)}/>
              </div>
              <div className="min-[290px]:w-1/3 min-[290px]:flex min-[290px]:justify-end">
                <ThemeProvider theme={theme}>
                  <Switch label="Lisävarusteet" size={`${window.innerWidth<700 ?'small':'medium'}`} color="warning"  onChange={() => setChecked(!checked)}/>
                </ThemeProvider>
                <span className={`align-middle text-center pt-1 min-[700px]:pt-2.5 ${checked ? 'text-[#ed6c02]':''}`}>Lisävarusteet</span>
              </div>
            </div>

          { filtered.length !== 0 ?
            <div className="pb-1">
              <div className="w-full">
                <div id="liikkeet" className="">                       
                  <table className='table:responsive w-full'>
                    <tbody className="" id="list">
                      {filtered.map((store,i)=>(
                        <StoreData key={i} store={store} />
                      ))}
                    </tbody>
                  </table>      
                </div>
              </div>
              <div className='z-10 flex justify-center align-middle items-center'>
                  <Pagination count={pageCount} page={page} onChange={goToPage} sx={{zIndex: 'modal'}} size={`${window.innerWidth<500 ?'small':'medium'}`}/>
              </div>

            </div>
            : <div> 
            {loadDone 
            ? <div>0 Hakutulosta</div>
            :<div className="loader-position"><div className="loader"></div></div>
            } 
          </div>}
        </div>
      </div>
)}

export default App;
