export  const onSuccess = (stores) => {
  const response = stores.map(function(liike){
    return {
      name: liike[0],
      link: liike[1],
      brands: liike[2],
      gear: liike[3],
      form: liike[5],
      orderIns: liike[4],
      orderLink: liike[6] || '',
      show: false
    }
  })
  return response
  
}